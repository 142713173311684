@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;0,700;1,300;1,400;1,700&display=swap');
@import "~bootstrap/scss/bootstrap";

@import 'Variables.scss';
@import 'components/UI/_animations';
@import 'components/UI/Logo';
@import 'components/UI/Wrapper';
@import 'components/UI/MainBackground';
@import 'components/UI/PageTitle';
@import 'components/Content/Content';

html, body, *{
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    color: $primary-color;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

ol, ul, li {
    list-style: none;
    padding: 0;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: $primary-color;
    margin-bottom: 1.25rem;
};

h2 {
    font-size: 1.2rem;
    color: $third-color;
    margin-bottom: 0.75rem;
};

p {
    font-size: 1.0rem;
    color:$secondary-color;
}
#backdrop-container {
    position: relative;
}

#modal-container {
    position: relative;
    top: 0;
    left: 0;
}

#root {
    min-height: 100vh;
}