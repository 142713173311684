@import 'Main/Main.scss';
@import 'Header/Header.scss';
@import 'Footer/Footer.scss';

#content {
    height: 100%;
    min-height: 100vh;
    width: 75%;
    max-width: 1320px;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 3% auto;
    padding: 50px 0px 60px;
    background-color: $content-background-color;
    -webkit-box-shadow: 0 0 40px -10px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 40px -10px rgba(0,0,0,.2);
    box-shadow: 0 0 40px -10px rgb(0 0 0 / 20%);
    border-radius: 40px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.5s ease-in-out;
};

@media only screen and (max-width: 1440px) and (min-width: 992px) {
    #content {
        max-width: 75%;
    }
}

@media only screen and (max-width: 991px) {
    #content {
        border-radius: 0;
        margin: 0;
        width: 100%;
        max-width: 991px;
        padding: 20px 0;
    }
}

@media only screen and (max-width: 767px) {
    #content {
        padding: 0;
    }
}