#gallery-modal {

    &.modal-dialog {
        width: 100%;
        max-width: unset;
        min-height: 100vh;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;  

        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 768px;
            pointer-events: auto;
            background-color: #222;
            background-clip: padding-box;
            border: unset;
            overflow: hidden;
            border-radius: 15px;
            outline: 0;

            .modal-wrapper {
                position: relative;
                flex: 1 1 auto;

                .modal-header {
                    align-items: center;
                    justify-content: flex-end;
                    padding: unset;
                    border-bottom: unset;
                    border-radius: unset;
                    position: relative;

                    .modal-title {
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        background-color: rgba(34, 34, 34, 0.5);
                        border-radius: 25px;
                        color: #fff;
                        font-weight: 700;
                        padding: 5px 10px;
                        transition: 0.2s ease-in-out;
                        pointer-events: none;

                        &:hover {
                            background-color: rgba(34, 34, 34, 1);
                        }
                    }

                    .modal-close-container {
                        position: absolute;
                        right: 20px;
                        top: 20px;

                        .button {
                            cursor: pointer;
                            position: relative;
                            height: 30px;
                            width: 30px;
                            background-color: rgba(34, 34, 34, 0.5);
                            border-radius: 25px;
                            transition: 0.2s ease-in-out;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                background-color: #222;
                            }
                        }
                    }
                }

                .modal-image-container {
                    max-width: 100%;
                    min-width: 100%;

                    > div {
                        max-width: 100%;
                        min-height: 460px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                .modal-actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    padding: 0 20px;

                    div {
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        background-color: #22222280;
                        border-radius: 25px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        transition: 0.2s ease-in-out;

                        &:hover {
                            background-color: #222222;
                        }
                    }
                }
            }
        }
    }
};

@media only screen and (min-width: 1280px) {
    #gallery-modal {

        &.modal-dialog {
    
            .modal-content {
                max-width: 1024px;

                .modal-wrapper {

                    .modal-image-container {

                        > div {
                            min-height: 680px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    #gallery-modal {

        &.modal-dialog {
    
            .modal-content {
                max-width: 720px;

                .modal-wrapper {

                    .modal-image-container {

                        > div {
                            min-height: 390px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #gallery-modal {

        &.modal-dialog {
    
            .modal-content {
                max-width: 100%;

                .modal-wrapper {

                    .modal-image-container {

                        > div {
                            min-height: 430px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    #gallery-modal {

        &.modal-dialog {
    
            .modal-content {

                .modal-wrapper {

                    .modal-image-container {

                        > div {
                            min-height: 400px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    #gallery-modal {

        &.modal-dialog {
    
            .modal-content {

                .modal-wrapper {

                    .modal-image-container {

                        > div {
                            min-height: 360px;
                        }
                    }
                }
            }
        }
    }
}