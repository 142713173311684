@import 'Introduction/Introduction';
@import 'WhatWeDo/WhatWeDo';
@import 'Portfolio/Portfolio';

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 10;

    section {
        padding: 0 70px;
    }
}

@media screen and (max-width: 1279px) {
    main {
    
        section {
            padding: 0 35px;
        }
    }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
    main {
    
        section {
            padding: 0 50px;
        }
    }
}