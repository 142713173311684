#introduction {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;

    .image-container {
        div {
            padding: 30px;
        }
    }

    h1 {
        font-size: 60px;

        span {
            font-weight: 700;
            // background: -webkit-linear-gradient(#f72585 0%, #d91f90 18%, #bf199a 38%, #b5179e 50%, #7209b7 100%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            color: #fff;
        }
    }

    .text {
        span {
            font-size:20px;

            div {
                margin-left: -5px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    #introduction {
        margin: 70px 0;

        .image-container {
            div {
                padding: 0;
            }
        }
    
        h1 {
            font-size: 40px;
        }

        .text {

            span {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #introduction {
        margin: 35px 0 55px 0;

        .image-container {
            margin-bottom: 35px;
        }
    }  
}

@media screen and (max-width: 499px) {
    #introduction {
        margin: 35px 0 55px 0;

        .text {
            text-align: center;

            span {

                &:first-child {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }
        }
    }  
}