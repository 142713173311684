#category-changer {
    margin-bottom: 25px;

    ul {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
            margin-left: 25px;
            cursor: pointer;
            color: #c7c7c7;
            transition: 0.25s ease-in-out;

            &.active {
                color: #fff;
            }

            &:hover {
                color: #fff;
            }
        }
    }
};

@media only screen and (max-width: 500px) {
    #category-changer {
        margin-bottom: 25px;

        .mobile-container {
            position: relative;
            display: flex;
            justify-content: flex-end;

            .selected-category-container {
                padding: 5px 15px;
                border: 2px solid #333;
                border-radius: 25px;
                background: #252525;
                width: 100px;
                text-align: center;
                transition: 0.35s ease-in-out;
                font-size: 20px;

                &.active {
                    border-color: #fff;
                }
            }

            .categories-container {
                position: relative;

                ul {
                    position: absolute;
                    flex-direction: column;
                    align-items: flex-start;
                    right: 0;
                    top: 50px;
                    z-index: 11;
                    border: 2px solid #333;
                    border-radius: 25px;
                    background: #252525;
                    width: 100px;
                    box-shadow: 2px 4px 5px 1px #000000b5;
                    overflow: hidden;

                    li {
                        margin: 0;
                        cursor: pointer;
                        color: #c7c7c7;
                        transition: 0.25s ease-in-out;
                        padding: 10px;
                        width: 100%;
                        text-align: center;
                        font-size: 20px;
            
                        &.active {
                            color: #fff;
                        }
            
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}