@keyframes rotation {
    0% { 
        transform: rotate(0deg) scale(1);
    }
    12% {
        transform: rotate(43.5deg) scale(1.0005);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(359deg); 
    } 
}

@keyframes line{
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke{
    0% {
        stroke: white;
        stroke-width: 5;
    }
    100% {
        stroke: transparent;
    }
}

@keyframes fill {
    0% {
        fill: transparent;
    }
    100% {
        fill: #fff;
    }
}

@keyframes appear {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

svg#logo-icon {
    animation: rotation 120s infinite linear;
    max-width: 100%;

    #path-1 {
        stroke-dasharray: 1903;
        stroke-dashoffset: 1903;
        stroke: white;
        stroke-width: 5;
        fill: transparent;
        animation: line 5.5s ease forwards, stroke 7s ease forwards 5.5s, fill 6s ease forwards 5.5s;
    }

    #path-2 {
        stroke-dasharray: 2214;
        stroke-dashoffset: 2214;
        stroke: white;
        stroke-width: 5;
        fill: transparent;
        animation: line 5.5s ease forwards 0.25s, stroke 7s ease forwards 5.75s;
    }

    #path-3 {
        animation: appear 6s ease forwards 5.75s;
    }
}

@media only screen and (max-width: 1420px) and (min-width: 992px) {
    svg#logo-icon {
        max-height: 240px;
    }
}

@media only screen and (max-width: 991px) {
    svg#logo-icon {
        max-height: 200px;
    }
}

@media screen and (max-width: 767px) {
    svg#logo-icon {
        max-height: 140px;
    }
}