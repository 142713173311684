header {
    width: 100%;
    z-index: 11;

    nav {
        padding: 0 300px;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;

            li {
                display: inline;

                a {
                    font-size: 1rem;
                    text-decoration: none;
                    color: #c7c7c7;
                    transition: 0.2s ease-in-out;
                    position: relative;
                    
                    &::before {
                        transition: 0.3s ease-in-out;
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 3px;
                        background-color: $primary-blue-color;
                        border-radius: 10px;
                        bottom: -5px;
                        left: 0;
                    }

                    &:hover {
                        color: #fff;

                        &::before {
                            width: 40%;
                        }
                    }

                    &.active {
                        color: #fff;

                        &::before {
                            width: 40%;
                        }
                    }
                }

                span {
                    font-size: 1rem;
                    text-decoration: none;
                    color: #c7c7c7;
                    transition: 0.2s ease-in-out;
                    position: relative;

                    &.active {
                        color: #fff;
                    }
                }

                & > div {
                    cursor: pointer;
                }
            }
        }
    }
}

#dropdown-container {
    position: relative;

    .card-inner {
        position: absolute;
        background-color: #1c1c1c;
        z-index: 99;
        top: 15px;
        left: -50%;
        box-shadow: 2px 3px 5px 2px #141414;
        border-radius: 25px;
        max-width: 150px;

        .table {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            margin: 0;

            > div {
                max-width: 33%;
                border: none;
                transition: 0.2s ease-in-out;

                &:nth-child(2) {
                    margin: 0 15px;
                }

                svg {
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            height: 3px;
            background-color: #007ced;
            border-radius: 10px;
            top: 0px;
            animation: show 0.25s ease-in-out forwards;
            //animation-delay: 0.25s;
        }

        .flash-message-container {
            position: relative;

            > div {
                position: absolute;
                right: -240px;
                top: -45px;
                background-color: #007ced;
                box-shadow: 2px 3px 5px 2px #141414;
                border-radius: 25px;
                padding: 5px 10px;

                span {
                    color: #f5f5f5;
                    font-weight: 500;
                }
            }
        }
    }
}

@keyframes show {
    from {
        width: 0;
        left: 75px
    }
    to {
        width: 60px;
        left: 45px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px){
    header {
        
        nav {
            padding: 0 200px;
        }
    }
}

@media only screen and (max-width: 1279px) {
    header {
        
        nav {
            padding: 0 150px;
        }
    }
}

@media only screen and (max-width: 990px) {
    #dropdown-container {

        .card-inner {

            .flash-message-container {

                > div {
                    left: 0;
                    right: auto;
                    top: 11px;
                    min-width: 230px;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 767px) {
    header {
        nav {
            position: relative;
    
            ul {
                position: absolute;
                right: 0;
                top: 0;
                flex-direction: column;
                justify-content: center;
                min-height: 100vh;
                padding: 0 35px 0 35px;
                min-width: 100px;
                z-index: 11;
                background: #141414ed;
                border-left: 3px solid #007ced;

                li {
                    margin-bottom: 20px;

                    &.last-child {
                        margin: 0;
                    }

                    a {
                        font-size: 1.25rem;
                    }
                }

                .close-icon-container {
                    position: absolute;
                    right: 55px;
                    top: 90px;
                }
            }
    
            .hamburger-icon-container {
                position: absolute;
                right: 50px;
                top: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 12;
                width: 40px;
                height: 40px;
                background-color: #666666c4;
                border-radius: 30px;
                padding: 5px;

                div {
                    width: 20px;
                    height: 3px;
                    background: #fff;
                    border-radius: 3px;
                    transition: 0.2s ease-in-out;
                    position: absolute;

                    &:nth-child(1) {
                        top: 13px;
                        width: 13px;
                        right: 10px;
                    }

                    &:nth-child(2) {
                        margin: 4px 0;
                    }

                    &:nth-child(3) {
                        top: 25px;
                    }
                }

                &.active {
                    
                    div {
                        &:nth-child(1) {
                            transform: rotate(45deg);
                            top: 18px;
                            width: 20px;
                        }
    
                        &:nth-child(2) {
                            opacity: 0;
                            margin: 0;
                        }

                        &:nth-child(3) {
                            transform: rotate(-45deg);
                            top: 18px;
                        }
                    }
                }
            }
        }
    }

    #dropdown-container {
        position: absolute;
        bottom: 65px;
        left: calc(50% - 20px);
    
        .card-inner {
            position: absolute;
            background-color: unset;
            z-index: 12;
            top: unset;
            bottom: 0;
            left: unset;
            box-shadow: unset;
            border-radius: unset;
            max-width: unset;
            min-width: 100%;
    
            .table {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0;
    
                > div {
                    max-width: 100%;
                    border: none;
                    transition: 0.2s ease-in-out;
    
                    &:nth-child(2) {
                        margin: 20px 0;
                    }
    
                    svg {
                        width: 40px;
                        height: 40px;
                    }
    
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
    
            &::before {
                display: none;
            }

            .flash-message-container {

                > div {
                    left: -250px;
                    right: auto;
                    top: -38px;
                    min-width: unset;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    header {

        nav {
    
            .hamburger-icon-container {
                right: 35px;
                top: 80px;
            }
        }
    }
}