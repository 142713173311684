#portfolio-list {

    .items-container {

        ul {
            margin: 0;
            display: grid;
            grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
            grid-gap: 20px;
            cursor: pointer;

            li {

                .item-container {
                    position: relative;

                    .image-container-outer {
                        overflow: hidden;
                        max-height: 250px;
                        border-radius: 15px;

                        .image-container {
                            min-height: 250px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-attachment: local;
                            transition: 0.25s ease-in-out;
                        }
                    }

                    .text-container {
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        background-color: #020202ea;
                        padding: 5px 10px;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 15px;
                        transition: 0.25s ease-in-out;
                        opacity: 0;
                    }
                }

                &:hover {
                    .image-container {
                        transform: scale(1.15);
                    }

                    .text-container {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
                grid-gap: 20px;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 150px;
    
                            .image-container {
                                min-height: 150px;
                            }
                        }
                    }
                }
            }
        }
    };
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
                grid-gap: 20px;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 200px;
    
                            .image-container {
                                min-height: 200px;
                            }
                        }
                    }
                }
            }
        }
    };
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
                grid-gap: 20px;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 170px;
    
                            .image-container {
                                min-height: 170px;
                            }
                        }
                    }
                }
            }
        }
    };
}
@media only screen and (max-width: 767px) {
    #portfolio-list {
        padding-bottom: 35px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: calc(50% - 10px) calc(50% - 10px);
                grid-gap: 20px;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 225px;
    
                            .image-container {
                                min-height: 225px;
                            }
                        }
                    }
                }
            }
        }
    };
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: calc(50% - 10px) calc(50% - 10px);
                grid-gap: 20px;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 175px;
    
                            .image-container {
                                min-height: 175px;
                            }
                        }
                    }
                }
            }
        }
    };
}

@media only screen and (max-width: 500px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: 100%;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 250px;
    
                            .image-container {
                                min-height: 200px;
                            }
                        }
                    }
                }
            }
        }
    };
}

@media only screen and (max-width: 300px) {
    #portfolio-list {

        .items-container {
    
            ul {
                grid-template-columns: 100%;
    
                li {
    
                    .item-container {
    
                        .image-container-outer {
                            max-height: 170px;
    
                            .image-container {
                                min-height: 170px;
                            }
                        }
                    }
                }
            }
        }
    };
}