#main-background {
    background-color: $main-background-color;
    // background-image: $main-background-image;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}