#page-title {
    padding: 30px 70px;
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    background-color: #252525;
    margin: 50px 0;

    h1 {
        margin: 0;
    }
}

@media only screen and (max-width: 500px) {
    #page-title {
        margin: 50px 0 25px;
    }
}