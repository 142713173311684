#what-we-do {
    h2 {
        display:inline-block;
        font-size: 1.4rem;
        font-weight: 600;
        color: $primary-color;
        margin: 0 0 30px 0;
        padding: 0 0 7px 0;
        position: relative;

        &::before {
            display: block;
            position: absolute;
            content: '';
            width: 100%;
            background-color: #353535;
            height: 2px;
            bottom: 0;
        }

        &::after {
            display: block;
            position: absolute;
            content: '';
            width: 30px;
            background-color: #007ced;
            height: 2px;
            bottom: 0;
        }
    }

    .info-list-element {
        margin-bottom: 25px;
    
        h3 {
            color: $primary-color;
            font-size: 1.1rem;
            margin: 0 0 7px 0;
            font-weight: 600;
        }
    
        p {
            color: $secondary-color;
            font-size: 0.9rem;
        }
    
        svg {
            width: 50px;
            height: 50px;
            fill: #007ced;
        }
    }
}

@media only screen and (max-width: 1420px) and (min-width: 992px) {
    #what-we-do {
        .info-list-element {
    
            h3 {
                font-size: 1.0rem;
                margin: 0 0 7px 5px;
            }
        
            p {
                font-size: 0.85rem;
                margin: 0 0 0 5px;
            }
        
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    #what-we-do {
        .info-list-element {
            h3 {
                margin: 0 0 7px 10px;
            }
        
            p {
                margin: 0 0 0 10px;
            }
        }
    }

}